.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  color: white !important;
}

.connectedWallet {
  border: 1px solid rgb(46, 174, 248) !important;
}

.outer-circle {
  /* bottom: 3.7rem; */
  left: 0;
  top: 0;
  bottom: 0;
  width: 173px;
  height: 166px;
  position: absolute;
  background-repeat: no-repeat;
  background-image: url("./../../assets/images/outer-circle.svg");
}
.inner-circle {
  left: 0.9em;
  top: 0.8em;
  bottom: 0;
  width: 203px;
  height: 165px;
  position: absolute;
  background-repeat: no-repeat;
  background-image: url("./../../assets/images/inner-circle.svg");
}

.inner-progress {
  width: 103px;
  height: 149px;
  left: 2em;
  top: 1.8em;
  bottom: 0;
  /* left: '270px'; */
  position: absolute;
}

.progress-divider {
  left: 6.1em;
  top: 2.6em;
  bottom: 0;
  width: 203px;
  height: 166px;
  position: absolute;
  background-repeat: no-repeat;
  background-image: url("./../../assets/images/progress-divider.svg");
}

.progress-buttons {
  left: 19.1em;
  top: 3.8em;
  bottom: 0;
  /* width: 203px;
  height: 166px; */
  position: absolute;
}

.download-size {
  left: 12.5em;
  top: 3.8em;
  bottom: 0;
  color: grey;
  position: absolute;
}

.download-speed {
  left: 12.5em;
  top: 9em;
  bottom: 0;
  position: absolute;
  color: grey;
  font-size: "14px";
}

.progress-bar-black {
  left: 11.5em;
  top: 6.5em;
  bottom: 0;
  width: 21.875em;
  height: 1.938em;
  position: absolute;
  background-repeat: no-repeat;
  background-image: url("./../../assets/images/BlackBars.svg");
}

.progress-bar-glow {
  left: 0;
  top: 0;
  bottom: 0;
  width: 21.875em;
  height: 1.938em;
  position: absolute;
  background-repeat: no-repeat;
  background-image: url("./../../assets/images/GlowingBars.svg");
}

.play-pause {
  left: 33em;
  top: 5.85em;
  bottom: 0;
  position: absolute;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: black !important;
}
.CircularProgressbar .CircularProgressbar-path {
  stroke: #ff6c00 !important;
}
.CircularProgressbar .CircularProgressbar-text {
  fill: white;
  font-size: 20px;
}

.active-tab {
  color: white !important;
}

.inactive-tab {
  color: #434343 !important;
}

.MuiButton-startIcon {
  width: 5% !important;
}
