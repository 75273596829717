.clickable {
  cursor: pointer;
}
.icon-row {
  width: 2em;
}
.icon-folder {
  width: 1em;
}
.icon-file {
  width: 0.75em;
}

.titlebar {
  -webkit-user-select: none;
  -webkit-app-region: drag;
}

.titlebar-button {
  -webkit-app-region: no-drag;
}